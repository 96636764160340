import { Fragment } from "react";

export function Speakers() {
    return (
        <Fragment>
            <section id="speakers" className="wow fadeInUp">
                <div className="container">
                    <div className="section-header">
                        <h2>Event Speakers</h2>
                        <p>Our speakers for FGC 2023</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className="speaker"
                                style={{
                                    backgroundImage:
                                        "url('https://rameyroadeus01.blob.core.windows.net/westover-content/8dfcc315-3283-4e52-9c86-fc9f0871e0ce-joe_conner.jpg')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    width: "100%",
                                    height: 650,
                                }}
                            >
                                <div className="details">
                                    <h3>
                                        <a href="/speakers-joe-conner">Joe Conner</a>
                                    </h3>
                                    <p>Elam Ministries</p>
                                    {/* <div className="social">
                                        <a href="">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-google-plus"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="speaker"
                                style={{
                                    backgroundImage:
                                        "url('https://rameyroadeus01.blob.core.windows.net/westover-content/71e334ae-b4bc-481f-baaa-45e8d733bde0-ken_katayama.png')",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    width: "100%",
                                    height: 650,
                                }}
                            >
                                <div className="details">
                                    <h3>
                                        <a href="/speakers-ken-katayama">Ken Katayama</a>
                                    </h3>
                                    <p>Crossover Global</p>
                                    {/* <div className="social">
                                        <a href="">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-google-plus"></i>
                                        </a>
                                        <a href="">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useAuth0 } from "@auth0/auth0-react";
import { Header } from "./Header";
import { Footer } from "./Footer";

import { getCart, getCartId } from "../store/Cart/CartSlice";
import { setUserToken } from "../store/Navigation/NavSlice";
import { RootState } from "../store";
import { setCalendar } from "../store/Events/EventSlice";
import { getPublicCalendar, getUserCalendar } from "../apiClients/contentApiClient";

export interface LoginParams {
    state: string;
}

export function Layout(props: any) {
    const dispatch = useDispatch();
    const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const { state } = useParams<LoginParams>();

    const { userToken } = useSelector((state: RootState) => state.nav);

    useEffect(() => {
        let cartId = getCartId();
        if (cartId) {
            dispatch(getCart(cartId));
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && user) {
            getIdTokenClaims().then((claims: any) => {
                localStorage.setItem("idToken", claims?.__raw as string);
                dispatch(setUserToken(claims?.__raw));
            });

            var decodedState = base64_decode(userToken);
            console.log("decodedState", decodedState);
        }
    }, [isAuthenticated, user]);

    const asyncTasks = async () => {
        if (userToken) {
            let events = await getUserCalendar(userToken, user?.sub as string);
            dispatch(setCalendar(JSON.stringify(events)));
        } else {
            let events = await getPublicCalendar();
            dispatch(setCalendar(JSON.stringify(events)));
        }
    };

    useEffect(() => {
        asyncTasks();
    }, [userToken]);

    return (
        <Fragment>
            <Header />
            <div>
                <Fragment>{props.children}</Fragment>
            </div>
            <Footer />
        </Fragment>
    );
}

import { Fragment, useEffect, useState } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";

import "./Header.css";

export function Header(props: any) {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

    const location = useLocation();

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {}, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {}, [scrollPosition]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    let headerClass = "";
    if (location.pathname !== "/") {
        headerClass = "header-scrolled";
    } else if (scrollPosition > 0) {
        headerClass = "header-scrolled";
    }
    return (
        <Fragment>
            <header id="header" className={headerClass}>
                <Navbar id="nav-menu-container" className="navbar navbar-expand-md fixed-top">
                    <div className="container">
                        <NavbarBrand tag={Link} to="/">
                            <img
                                // src="/assets/logo-dark.png"
                                src="https://www.westoverchurch.com/views/site/images/logo-white.png"
                                alt="Westover Church"
                                className="menu-logo p-1"
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2">
                            <Fragment>
                                <FontAwesomeIcon icon={["fas", "bars"]} color="white" fontSize={26} />
                            </Fragment>
                        </NavbarToggler>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                            <Nav className="navbar-nav flex-grow">
                                <NavItem
                                    className={
                                        location.pathname === "/" &&
                                        (location.hash === "" || location.hash === "#intro")
                                            ? "menu-active"
                                            : ""
                                    }
                                >
                                    <HashLink className="nav-link" to="/#intro" onClick={toggleNavbar}>
                                        Home
                                    </HashLink>
                                </NavItem>
                                <NavItem className={location.hash === "#speakers" ? "menu-active" : ""}>
                                    <HashLink className="nav-link" to="/#speakers" onClick={toggleNavbar}>
                                        Speakers
                                    </HashLink>
                                </NavItem>
                                <NavItem className={location.hash === "#schedule" ? "menu-active" : ""}>
                                    <HashLink className="nav-link" to="/#schedule" onClick={toggleNavbar}>
                                        Schedule
                                    </HashLink>
                                </NavItem>
                                {isAuthenticated && (
                                    <NavItem className={location.pathname === "/calendar" ? "menu-active" : ""}>
                                        <NavLink tag={Link} className="nav-link" to="/calendar" onClick={toggleNavbar}>
                                            Calendar
                                        </NavLink>
                                    </NavItem>
                                )}
                                {isAuthenticated && (
                                    <NavItem className={location.pathname === "/resources" ? "menu-active" : ""}>
                                        <NavLink tag={Link} className="nav-link" to="/resources" onClick={toggleNavbar}>
                                            Resources
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem className={location.pathname === "/giving" ? "menu-active" : ""}>
                                    <NavLink tag={Link} className="nav-link" to="/giving" onClick={toggleNavbar}>
                                        Give
                                    </NavLink>
                                </NavItem>
                                {!isAuthenticated && (
                                    <NavItem>
                                        <NavLink tag={Link} onClick={() => loginWithRedirect()}>
                                            Log In
                                        </NavLink>
                                    </NavItem>
                                )}
                                {isAuthenticated && (
                                    <Fragment>
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="nav-link"
                                                to="/logout"
                                                onClick={toggleNavbar}
                                            >
                                                Log Out
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>
                                )}
                                {/* <NavItem className={activeTab === "catalog" ? "active" : ""}>
                            <NavLink
                                tag={Link}
                                className="nav-link"
                                to="/catalog"
                                onClick={toggleNavbar}
                            >
                                All Items
                            </NavLink>
                        </NavItem> */}
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        </Fragment>
    );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DynamicPage } from "../../@types/dynamicPage";

const NavSlice = createSlice({
    name: "NavigationSlice",
    initialState: {
        userToken: "",
        activeTab: "home",
        activePage: {} as DynamicPage,
    },
    reducers: {
        setUserToken(state: any, action: PayloadAction<string>) {
            state.userToken = action.payload;
        },
        setActiveTab(state: any, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setActivePage(state: any, action: PayloadAction<string>) {
            state.activePage = JSON.parse(action.payload);
        },
    },
    extraReducers: (builder) => {},
});

export const { setUserToken, setActiveTab, setActivePage } = NavSlice.actions;

export default NavSlice.reducer;

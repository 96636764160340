import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setCalendar } from "../../store/Events/EventSlice";
import { Spinner } from "../../components/shared/Spinner";

export function Logout() {
    const dispatch = useDispatch();
    const { logout } = useAuth0();

    useEffect(() => {
        dispatch(setCalendar("[]"));
        localStorage.removeItem("idToken");

        logout({
            returnTo: window.location.origin,
        });
    }, []);

    return (
        <Fragment>
            <Spinner />
        </Fragment>
    );
}

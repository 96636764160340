import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { Cart } from "../../@types/item";
import { getOrCreateCart } from "../../apiClients/contentApiClient";

export const getCartId = () => {
    var cartId = localStorage.getItem("wc_cart_id");
    if (!cartId || cartId === "") {
        cartId = uuid();
        // const smallId = cartId.slice(0, 8);
        localStorage.setItem("wc_cart_id", cartId as string);
    }
    return cartId;
};

export const getCart = createAsyncThunk("cartId.generate", async (cartId: string) => {
    try {
        return await getOrCreateCart(cartId);
    } catch (error) {
        return Promise.reject(error);
    }
});

const CartSlice = createSlice({
    name: "CartSlice",
    initialState: {
        loading: true as boolean,
        cart: {} as Cart,
    },
    reducers: {
        setCart(state: any, action: PayloadAction<string>) {
            state.cart = JSON.parse(action.payload) as Cart;
        },
    },
    extraReducers: (builder) => {
        // Generate cartId
        builder.addCase(getCart.pending, (state) => {});

        builder.addCase(getCart.fulfilled, (state, action) => {
            state.cart = action.payload as Cart;
            state.loading = false;
        });

        builder.addCase(getCart.rejected, (state, action) => {
            console.error(`Failed to generate cart id: ${action.payload}`);
            state.loading = false;
        });
    },
});

export const { setCart } = CartSlice.actions;

export default CartSlice.reducer;

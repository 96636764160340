import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupedEvents } from "../../@types/events";

const EventSlice = createSlice({
    name: "EventSlice",
    initialState: {
        calendar: [] as Array<GroupedEvents>,
    },
    reducers: {
        setCalendar(state: any, action: PayloadAction<string>) {
            state.calendar = JSON.parse(action.payload) as Array<GroupedEvents>;
        },
    },
    extraReducers: (builder) => {},
});

export const { setCalendar } = EventSlice.actions;

export default EventSlice.reducer;

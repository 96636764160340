import { Fragment } from "react";

import { Intro } from "./components/Intro";
import { About } from "./components/About";
import { Speakers } from "./components/Speakers";
import { Schedule } from "./components/Schedule";

import "./Pages.css";

export function Home() {
    return (
        <Fragment>
            <Intro />
            <main id="main">
                <About />
                <Speakers />
                <Schedule />
            </main>
            {/* <Container style={{ alignContent: "center", alignItems: "center" }}>
                <img src={comingSoonImage} alt="FGC 2023" style={{width: "100%"}} />
                <div style={{ alignContent: "center", alignItems: "center", textAlign: "center" }}>
                    <br />
                    <a
                        className="btn btn-lg btn-primary mx-1"
                        href="https://pushpay.com/p/westoverchurch"
                        style={{ alignSelf: "center" }}
                    >
                        Give Online
                    </a>
                </div>
            </Container> */}
            {/* <Content>
                <img
                    className="floating-logo"
                    alt="International Market"
                    src="https://rameyroadeus01.blob.core.windows.net/westover-content/247f4453-e155-4abd-8a55-5f4640eac745-International_Market_white-09.png"
                />
            </Content> */}
        </Fragment>
    );
}

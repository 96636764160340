import { Fragment, useEffect } from "react";

import "./Footer.css";

export function Footer(props: any) {
    useEffect(() => {}, []);

    return (
        <Fragment>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 footer-info">
                                <div className="row">
                                    <div className="col-2">
                                        <img
                                            src="https://www.westoverchurch.com/views/site/images/footer_logo.png"
                                            alt="TheEvenet"
                                        />
                                    </div>
                                    <div className="col-10">© 2023 WESTOVER CHURCH. ALL RIGHTS RESERVED.</div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className="fa fa-angle-right"></i>{" "}
                                        <a href="https://www.westoverchurch.com/privacy-policy/" target={"_blank"}>
                                            Privacy policy
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Contact Us</h4>
                                <p>
                                    505 Muirs Chapel Rd. <br />
                                    Greensboro, NC 27410
                                    <br />
                                    United States <br />
                                    <strong>Phone:</strong> 336-299-7374
                                    <br />
                                    <strong>Email:</strong>{" "}
                                    <a href="mailto:info@westoverchurch.com">info@westoverchurch.com</a>
                                    <br />
                                </p>

                                {/* <div className="social-links">
                                    <a href="#" className="twitter">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                    <a href="#" className="facebook">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                    <a href="#" className="instagram">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                    <a href="#" className="google-plus">
                                        <i className="fa fa-google-plus"></i>
                                    </a>
                                    <a href="#" className="linkedin">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";

import { Event, GroupedEvents } from "../@types/events";
import { RootState } from "../store";
import { Link } from "react-router-dom";

export function Calendar() {
    const { calendar } = useSelector((state: RootState) => state.events);

    const { isAuthenticated, user } = useAuth0();

    const [eventList, setEventList] = useState<any | null>(null);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let evts: Array<any> = [];
        if (calendar && calendar.length) {
            calendar.map((group: GroupedEvents, g: number) => {
                group?.events.map((event: Event, e: number) => {
                    evts.push({
                        url: "#",
                        id: event.eventDataId,
                        title: event.title,
                        start: event.startDateTime,
                        end: event.endDateTime,
                        backgroundColor: getBackgroundColor(event),
                        borderColor: "#FFFFFF",
                        details: event,
                    });
                });
            });
            setEventList(evts);
        }
    }, [calendar]);

    const getBackgroundColor = (event: Event) => {
        switch (event.eventTypeKey) {
            case 1:
                return "#5e87f5";
            case 2:
                return "#3d4b91";
            case 3:
                return "#800080";
            case 4:
                return "#800080";
        }
    };

    const renderEventContent = (eventInfo: any) => {
        return (
            <Fragment>
                <b>{eventInfo.event.title}</b>
                <br />
                <small>{eventInfo.timeText}</small>
            </Fragment>
        );
    };

    const getFullScheduleLink = (userId: string) => {
        return `https://rameyroad-westover-content.azurewebsites.net/api/Calendar/user/${userId}/calendar`;
    };

    const getMapLink = (event: Event) => {
        let location = event.location;

        if (location) {
            let link = `https://www.google.com/maps/place/${location.street1},+${location.city},+${location.state}+${location.zip}`;

            return (
                <a target="_blank" href={link}>
                    {event.location.title}
                    {event.subLocation ? ` - ${event.subLocation}` : ""}
                </a>
            );
        }
        return "";
    };

    return (
        <main id="main">
            {selectedEvent && (
                <Modal isOpen={true} toggle={() => setSelectedEvent(null)}>
                    <ModalHeader>{selectedEvent?.title}</ModalHeader>
                    <ModalBody>
                        {selectedEvent?.description}
                        <br />
                        {Intl.DateTimeFormat(navigator.language, {
                            weekday: "long",
                            month: "short",
                            day: "numeric",
                        }).format(new Date(selectedEvent?.startDateTime))}
                        <br />
                        {Intl.DateTimeFormat(navigator.language, {
                            hour: "numeric",
                            minute: "2-digit",
                        }).format(new Date(selectedEvent?.startDateTime))}
                        &nbsp;-&nbsp;
                        {Intl.DateTimeFormat(navigator.language, {
                            hour: "numeric",
                            minute: "2-digit",
                        }).format(new Date(selectedEvent?.endDateTime))}
                        <br />
                        {selectedEvent?.location && getMapLink(selectedEvent)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="btn btn-sm" onClick={() => setSelectedEvent(null)}>
                            Dismiss
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
            <Container style={{ marginTop: 100, marginBottom: 50 }}>
                <div>
                    {eventList && (
                        <FullCalendar
                            plugins={[dayGridPlugin, listPlugin, interactionPlugin, timeGridPlugin]}
                            initialView="timeGridWeek"
                            eventTimeFormat={{
                                hour: "2-digit",
                                minute: "2-digit",
                                meridiem: false,
                            }}
                            headerToolbar={{
                                start: "title",
                                center: "",
                                end: "dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            footerToolbar={{
                                start: "", // will normally be on the left. if RTL, will be on the right
                                end: "today prev,next", // will normally be on the right. if RTL, will be on the left
                            }}
                            buttonText={{
                                today: "Today",
                                month: "Month",
                                week: "Week",
                                list: "Day",
                                timeGrid: "Day",
                            }}
                            slotMinTime="06:00:00"
                            slotMaxTime="22:00:00"
                            initialDate={new Date()}
                            // navLinks={true}
                            events={eventList}
                            eventClick={(info: any) => {
                                info.jsEvent.preventDefault();
                                let found = eventList.find((e: any) => info.event.id === e.id);
                                setSelectedEvent(found.details);
                            }}
                            eventContent={renderEventContent}
                            firstDay={0}
                            contentHeight={875}
                            themeSystem="bootstrap5"
                        />
                    )}
                </div>
                {isAuthenticated && (
                    <div className="row mt-5 no-print">
                        <div className="col-12 text-center">
                            <a href={getFullScheduleLink(user?.sub as string)} className="download-link">
                                <h4>
                                    Download full calendar{" "}
                                    <FontAwesomeIcon icon={["fas", "calendar"]} color="#ccb55f" fontSize={24} />
                                </h4>
                            </a>
                        </div>
                        <div className="col-xs-12 col-sm-4 text-center mt-3">
                            <img
                                src="https://rameyroadeus01.blob.core.windows.net/westover-content/98862f51-1e8a-40c1-9d82-5feebc0ea539-ios.png"
                                width="50"
                            />
                            &nbsp;
                            <Link to="/subscribe-calendar-ios">Instructions for iOS</Link>
                        </div>
                        <div className="col-xs-12 col-sm-4 text-center mt-3">
                            <img
                                src="https://rameyroadeus01.blob.core.windows.net/westover-content/2cbcb4da-4373-40d7-8867-47fb74f37a1c-andoid.png"
                                width="37"
                            />
                            &nbsp;
                            <Link to="/subscribe-calendar-google">Instructions for Android</Link>
                        </div>
                        <div className="col-xs-12 col-sm-4 text-center mt-3">
                            <img
                                src="https://rameyroadeus01.blob.core.windows.net/westover-content/97eda4a1-28c3-4b38-bfd3-9b4bba29f6f1-outlook.png"
                                width="50"
                            />
                            &nbsp;
                            <Link to="/subscribe-calendar-outlook">Instructions for Outlook</Link>
                        </div>
                    </div>
                )}
            </Container>
        </main>
    );
}

export default Calendar;

import * as React from "react";
import { FC } from "react";
import { Card, CardBody, CardFooter, CardTitle, Container } from "reactstrap";

interface UnderConstructionProps {
    msg: string;
}

const UnderConstruction: FC<UnderConstructionProps> = ({ msg }) => {
    return (
        <Container
            style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                marginTop: 200,
            }}
        >
            <h1>{msg ?? "Coming Soon!"}</h1>
        </Container>
    );
};

export default UnderConstruction;

import React, { Fragment, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "./shared/Spinner";

export const ProtectedRoute: React.FC = (props) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnUrl: window.location.href,
                },
            });
        }
    }, [isAuthenticated]);

    return <Fragment>{!isAuthenticated ? <Spinner /> : <Fragment>{props.children}</Fragment>}</Fragment>;
};

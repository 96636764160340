import { Route } from "react-router";
import { Provider } from "react-redux";
import { Switch } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Auth0Provider } from "@auth0/auth0-react";
import { store } from "./store";

import { Layout } from "./components/Layout";
import { Home } from "./pages/Home";
import { Content } from "./components/content/Content";
import { ItemList } from "./components/catalog/ItemList";
import { Cart } from "./components/catalog/Cart";
import { ItemDetail } from "./components/catalog/ItemDetail";
import { Checkout } from "./components/checkout/Checkout";
import { CheckoutComplete } from "./components/checkout/CheckoutComplete";
import { Logout } from "./pages/components/Logout";
import UnderConstruction from "./components/UnderConstruction";

import "./styles/custom.scss";
import "./styles/screens.scss";
import "./styles/bootstrapCustom.scss";
import { UserInfo } from "./pages/UserInfo";
import Calendar from "./pages/Calendar";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ContentByName } from "./components/content";

library.add(fab, fas);

const CalendarPage: React.FC = () => (
    <ProtectedRoute>
        <Calendar />
    </ProtectedRoute>
);

const UserInfoPage: React.FC = () => (
    <ProtectedRoute>
        <UserInfo />
    </ProtectedRoute>
);

const ResourcesPage: React.FC = () => (
    <ProtectedRoute>
        <ContentByName pageName="resources" />
    </ProtectedRoute>
);

function App() {
    return (
        <Auth0Provider
            domain="westover.us.auth0.com"
            clientId="QVd1cHOlHYpf4tgJ1HOWzGug9JBSIPX8"
            redirectUri={window.location.origin}
        >
            <div className="App">
                <Provider store={store}>
                    <Layout>
                        <Switch>
                            {/* <Route path="/catalog/item/:itemId" component={ItemDetail} /> */}
                            {/* <Route path="/catalog/:categoryName" component={ItemList} exact /> */}
                            {/* <Route path="/checkout/complete" component={CheckoutComplete} exact /> */}
                            {/* <Route path="/checkout" component={Checkout} exact /> */}
                            {/* <Route path="/cart" component={Cart} exact /> */}
                            {/* <Route path="/catalog" component={ItemList} exact /> */}
                            <Route path="/logout" component={Logout} exact />
                            <Route path="/calendar" component={CalendarPage} exact />
                            <Route path="/user-info" component={UserInfoPage} />
                            <Route path="/resources" component={ResourcesPage} />
                            <Route path="/coming-soon" component={UnderConstruction} />
                            <Route path="/:pageName" component={Content} />
                            <Route path="/" component={Home} exact />
                        </Switch>
                    </Layout>
                </Provider>
            </div>
        </Auth0Provider>
    );
}

export default App;

import { configureStore } from "@reduxjs/toolkit";

import navReducer from "./Navigation/NavSlice";
import eventReducer from "./Events/EventSlice";
import cartReducer from "./Cart/CartSlice";

export const store = configureStore({
    reducer: {
        nav: navReducer,
        events: eventReducer,
        cart: cartReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

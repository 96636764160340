import { Fragment } from "react";

import backgroundImage from "../../assets/other/earth-from-orbit_01.png";

export function Intro() {
    return (
        <Fragment>
            <section
                id="intro"
                style={{
                    // backgroundImage: `url(${backgroundImage})`,
                    backgroundImage:
                        "url(https://rameyroadeus01.blob.core.windows.net/westover-content/a7bc185f-9001-4cac-aa82-2c2bcf903bcc-fgc_2023_globe.png)",
                    height: "75vh",
                }}
            >
                <div className="intro-container wow fadeIn">
                    <h1 className="mb-4 pb-0">
                        A Global Crisis, A God of Hope
                        <small></small>
                        <br />
                        <span>2023 Fall Global Celebration</span>
                    </h1>
                    <p className="mb-4 pb-0">November 5-12, Westover Church</p>
                    {/* <a
                        href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
                        className="venobox play-btn mb-4"
                        data-vbtype="video"
                        data-autoplay="true"
                    ></a> */}
                    {/* <a href="#about" className="about-btn scrollto">
                        About
                    </a> */}
                </div>
            </section>
        </Fragment>
    );
}

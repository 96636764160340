import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { RootState } from "../../store";
import { Event, GroupedEvents } from "../../@types/events";

interface ScheduledEventProps {
    event: Event;
}

const ScheduledEvent: React.FC<ScheduledEventProps> = ({ event }) => {
    const { user } = useAuth0();

    let start = new Date(event.startDateTime);
    let end = new Date(event.endDateTime);
    let type = event.eventTypeKey;

    const getMapLink = (event: Event) => {
        let location = event.location;

        if (location) {
            let link = `https://www.google.com/maps/place/${location.street1},+${location.city},+${location.state}+${location.zip}`;

            return (
                <a target="_blank" href={link}>
                    {event.location.title}
                    {event.subLocation ? ` - ${event.subLocation}` : ""}
                </a>
            );
        }
        return "";
    };

    return (
        <div className={`row schedule-item event-type-${type}`}>
            <div className="col-md-2">
                <time>
                    {Intl.DateTimeFormat("en", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    }).format(start)}
                    -
                    {Intl.DateTimeFormat("en", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    }).format(end)}
                </time>
            </div>
            <div className="col-md-10">
                <h4>{event.title}</h4>
                <p>{event.description}</p>
                {event.location && getMapLink(event)}
                {/* <p>
                    <a
                        href={`https://rameyroad-westover-content.azurewebsites.net/api/Calendar/event/${event.eventDataId}`}
                        className="download-link"
                        target={"_blank"}
                    >
                        Download <FontAwesomeIcon icon={["fas", "calendar"]} color="#ccb55f" fontSize={16} />
                    </a>
                </p> */}
            </div>
        </div>
    );
};

export function Schedule() {
    const { calendar } = useSelector((state: RootState) => state.events);
    const [activeDay, setActiveDay] = useState<number>(0);

    return (
        <Fragment>
            <section id="schedule" className="section-with-bg">
                {calendar && (
                    <div className="container wow fadeInUp">
                        <div className="section-header">
                            <h2>Event Schedule</h2>
                            <p> Fall Global Celebration schedule of events</p>
                        </div>

                        <div className="row">
                            <div className="col-4 col-md-12">
                                <ul className="nav nav-tabs" role="tablist">
                                    {calendar?.length &&
                                        calendar.map((group: GroupedEvents, index: number) => {
                                            return (
                                                <li className="nav-item mb-2" key={index}>
                                                    <a
                                                        className={activeDay === index ? "nav-link active" : "nav-link"}
                                                        role="tab"
                                                        data-toggle="tab"
                                                        onClick={() => setActiveDay(index)}
                                                    >
                                                        {Intl.DateTimeFormat(navigator.language, {
                                                            weekday: "short",
                                                            month: "short",
                                                            day: "numeric",
                                                        }).format(new Date(group.startDate))}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                            <div className="col-8 col-md-12">
                                {/* <h3 className="sub-heading">Fall Global Celebration schedule of events.</h3> */}
                                <div className="tab-content row justify-content-center">
                                    {calendar?.length &&
                                        calendar.map((group: GroupedEvents, index: number) => {
                                            if (index !== activeDay) {
                                                return <Fragment key={index} />;
                                            } else {
                                                return (
                                                    <div
                                                        key={index}
                                                        role="tabpanel"
                                                        className="col-lg-9 tab-pane fade show active"
                                                        id={`day-${index}`}
                                                    >
                                                        {group.events.map((event: Event, index: number) => (
                                                            <ScheduledEvent key={index} event={event} />
                                                        ))}
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </Fragment>
    );
}

import { Fragment } from "react";

export function About() {
    return (
        <Fragment>
            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>About Fall Global Celebration</h2>
                            <p>Our annual celebration of what God is doing around the world.</p>
                        </div>
                        <div className="col-lg-3">
                            <h3>Where</h3>
                            <p>Westover Church</p>
                        </div>
                        <div className="col-lg-3">
                            <h3>When</h3>
                            <p>November 5-12, 2023</p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Block } from "../../@types/dynamicPage";
import { setActivePage, setActiveTab } from "../../store/Navigation/NavSlice";

import { RootState } from "../../store/index";

import { ColumnBlock, HtmlBlock, ImageBlock, ImageGalleryBlock, PrimaryImage } from ".";
import { getPageByName, getStartPage } from "../../apiClients/contentApiClient";
import { Spinner } from "../shared/Spinner";

import { ContentParams } from "./ContentByName";

import "./Content.scss";

export function Content(props: any) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { pageName } = useParams<ContentParams>();

    const { activePage } = useSelector((state: RootState) => state.nav);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getContent();
    }, [pageName]);

    const getContent = async () => {
        try {
            let pageContent = null;
            if (pageName == null || pageName === "") {
                pageContent = await getStartPage();
            } else {
                pageContent = await getPageByName(pageName);
            }
            dispatch(setActivePage(JSON.stringify(pageContent)));

            setLoading(false);
        } catch (error) {
            console.log(error);
            history.push("/coming-soon");
        }
    };

    const renderBlock = (block: Block, index: number) => {
        switch (block.type) {
            case "Piranha.Extend.Blocks.HtmlBlock":
                return <HtmlBlock block={block} />;
            case "Piranha.Extend.Blocks.ColumnBlock":
                return <ColumnBlock block={block} />;
            case "Piranha.Extend.Blocks.ImageGalleryBlock":
                return <ImageGalleryBlock block={block} />;
            case "Piranha.Extend.Blocks.ImageBlock":
                return <ImageBlock block={block} />;
            default:
                return (
                    <Fragment>
                        <div>No block renderer for block type {block.type}</div>
                    </Fragment>
                );
        }
    };

    useEffect(() => {
        if (activePage) {
            dispatch(setActiveTab(pageName));
        }
    }, [activePage]);

    const renderBlockContent = () => {
        if (activePage && activePage.blocks) {
            return (
                <Fragment>
                    {activePage.blocks.map((block: Block, index: number) => {
                        return <Fragment key={index}>{renderBlock(block, index)}</Fragment>;
                    })}
                </Fragment>
            );
        }
        return <Spinner />;
    };

    return loading ? (
        <Spinner />
    ) : (
        <Fragment>
            {/* {props.children} */}
            {/* <PrimaryImage /> */}
            <main id="main" className="main-page">
                <section id="speakers-details" className="wow fadeIn">
                    <div className="container">
                        <div className="section-header">
                            <h2>{activePage.title}</h2>
                        </div>
                        <div className="row">
                            <div className="col-12">{renderBlockContent()}</div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
}

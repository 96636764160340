import { Fragment } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import "./Pages.css";
import { Col, Container, Row } from "reactstrap";

export function UserInfo() {
    const { user } = useAuth0();
    let idToken = localStorage.getItem("idToken") || "";

    return (
        <Fragment>
            <main id="main" className="main-page">
                <Container>
                    <Row>
                        <Col xs={2}>
                            <label>Name:</label>
                        </Col>
                        <Col xs={10}>{user?.name}</Col>
                        <Col xs={2}>
                            <label>Nick name:</label>
                        </Col>
                        <Col xs={10}>{user?.nickname}</Col>
                        <Col xs={2}>
                            <label>Given Name:</label>
                        </Col>
                        <Col xs={10}>{user?.given_name}</Col>
                        <Col xs={2}>
                            <label>Family Name:</label>
                        </Col>
                        <Col xs={10}>{user?.given_name}</Col>
                        <Col xs={2}>
                            <label>JSON user:</label>
                        </Col>
                        <Col xs={10}>{JSON.stringify(user)}</Col>
                        <Col xs={2}>
                            <label>Token:</label>
                        </Col>
                        <Col xs={10}>
                            {idToken}
                            <br />
                            <a rel="noreferrer" href={"https://jwt.io/?token=" + idToken} target="_blank">
                                View on JWT.io
                            </a>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Fragment>
    );
}

export default withAuthenticationRequired(UserInfo, {
    onRedirecting: () => <Fragment />,
});

import axios from "axios";
import fileDownload from "js-file-download";
import { DynamicPage } from "../@types/dynamicPage";
import { Cart, CartUser, Item } from "../@types/item";

const base64 = require("base-64");

const restConfig = {
    rootUrl: process.env.REACT_APP_API_ROOT,
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
};

const getBasicAuthHeader = () => {
    return new Headers({
        Authorization: "Basic " + base64.encode(`${restConfig.username}:${restConfig.password}`),
        "Content-Type": "application/json",
    });
};

export const getAllItems = async () => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/catalog`, {
            method: "get",
            headers: getBasicAuthHeader(),
        });

        return (await response.json()) as Array<Item>;
    } catch (error) {
        console.error("getAllItems", error);
        return Promise.reject(error);
    }
};

export const searchAllItems = async (searchTerm: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/catalog/by-search-term/${searchTerm}`, {
            method: "get",
            headers: getBasicAuthHeader(),
        });

        return (await response.json()) as Array<Item>;
    } catch (error) {
        console.error("getAllItems", error);
        return Promise.reject(error);
    }
};

export const getItemById = async (itemId: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/catalog/by-item-id/${itemId}`, {
            method: "get",
            headers: getBasicAuthHeader(),
        });

        return (await response.json()) as Item;
    } catch (error) {
        console.error("getItemById", error);
        return Promise.reject(error);
    }
};

export const getOrCreateCart = async (cartId: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}`, {
            method: "post",
            headers: getBasicAuthHeader(),
        });

        return (await response.json()) as Cart;
    } catch (error) {
        console.error("getOrCreateCart", error);
        return Promise.reject(error);
    }
};

export const addCartItem = async (cartId: string, itemId: string, quantity: number, amount: number) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}/items/${itemId}/${quantity}/${amount}`, {
            method: "post",
            headers: getBasicAuthHeader(),
        });

        let jsonResult = await response.json();
        return jsonResult as Cart;
    } catch (error) {
        console.error("addCartItem", error);
        return Promise.reject(error);
    }
};

export const addUpdateCartUser = async (cartId: string, cartUser: CartUser) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}/user`, {
            method: "post",
            headers: getBasicAuthHeader(),
            body: JSON.stringify(cartUser),
        });

        let jsonResult = await response.json();
        return jsonResult as Cart;
    } catch (error) {
        console.error("addCartUser", error);
        return Promise.reject(error);
    }
};

export const updateCartItem = async (cartId: string, itemId: string, quantity: number, amount: number) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}/items/${itemId}/${quantity}/${amount}`, {
            method: "put",
            headers: getBasicAuthHeader(),
        });

        let jsonResult = await response.json();
        return jsonResult as Cart;
    } catch (error) {
        console.error("updateCartItem", error);
        return Promise.reject(error);
    }
};

export const deleteCartItem = async (cartId: string, itemId: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}/items/${itemId}`, {
            method: "delete",
            headers: getBasicAuthHeader(),
        });

        let jsonResult = await response.json();
        return jsonResult as Cart;
    } catch (error) {
        console.error("deleteCartItem", error);
        return Promise.reject(error);
    }
};

export const completeCart = async (cartId: string, paymentToken: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/cart/${cartId}/complete/${paymentToken}`, {
            method: "post",
            headers: getBasicAuthHeader(),
        });

        let jsonResult = await response.json();
        return jsonResult as Cart;
    } catch (error) {
        console.error("completeCart", error);
        return Promise.reject(error);
    }
};

export const getUserCalendar = async (idToken: string, userId: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/Events/by-user-id/${userId}`, {
            method: "get",
            headers: new Headers({
                Authorization: `Bearer ${idToken}`,
            }),
        });

        return (await response.json()) as Item;
    } catch (error) {
        console.error("getUserCalendar", error);
        return [];
    }
};

export const getPublicCalendar = async () => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/Events`, {
            method: "get",
            headers: getBasicAuthHeader(),
        });

        return (await response.json()) as Item;
    } catch (error) {
        console.error("getPublicCalendar", error);
        return Promise.reject(error);
    }
};

export const getStartPage = async () => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/content/start`, {
            method: "get",
            headers: new Headers({
                Authorization: "Basic " + base64.encode(`${restConfig.username}:${restConfig.password}`),
                "Content-Type": "application/json",
            }),
        });

        return (await response.json()) as DynamicPage;
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};

export const getPageByName = async (pageName: string) => {
    try {
        let response = await fetch(`${restConfig.rootUrl}/api/content/${pageName}`, {
            method: "get",
            headers: new Headers({
                Authorization: "Basic " + base64.encode(`${restConfig.username}:${restConfig.password}`),
                "Content-Type": "application/json",
            }),
        });

        return (await response.json()) as DynamicPage;
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};
